import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6485c11f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  style: {"max-width":"85vw"},
  class: "flex h-100 w-100 bg-light"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args))),
    class: "modal-container vw-100 vh-100 bg-dark d-flex align-items-center justify-content-center position-fixed top-0 bottom-0 left-0 right-0 m-0"
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
      class: "bg-light"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          style: {"max-height":"85vh","max-width":"85vw"},
          src: _ctx.imageUrl,
          alt: ""
        }, null, 8, _hoisted_2)
      ])
    ])
  ]))
}