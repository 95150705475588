
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {
  },
})
export default class GalleryModal extends Vue {
  @Prop({ required: true }) readonly imageUrl!: string;

  onClose():void {
    this.$emit('close');
  }
}
