
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
// eslint-disable-next-line
import { GeoJsonObject } from 'geojson';
import { reproject } from 'reproject';
import proj4 from 'proj4';
import fileDownload from 'js-file-download';
import ApiCalls from '@/services/api-calls';
import GalleryModal from '@/components/GalleryModal.vue';
import Loader from '@/components/Loader.vue';
import { Item as ItemModel, Media as MediaModel } from '@/models/index';
import { getFileExtension, getFileExtensionsPerMediaType } from '@/helpers/media';
import store from '@/store';

@Options({
  components: { GalleryModal, Loader },
})
export default class Item extends Vue {
  api = new ApiCalls();

  itemId: number | null = null;

  item: ItemModel | null = null;

  labelMappings: { propertyId: number, label: string }[] = [];

  hasLocation = false;

  location: { lat: number, lon: number } | null = null;

  geoJSON: GeoJsonObject | null = null;

  media: MediaModel[] | null = null;

  tags: string[] = []

  ontologyNames: string[] = [];

  selectedMediaUrl = '';

  isConfigLoaded = false;

  isMounted = false;

  getFileExtension = getFileExtension;

  getFileExtensionsPerMediaType = getFileExtensionsPerMediaType;

  @Watch('$store.getters.isConfigLoaded', { immediate: true })
  onConfigurationLoadedChange(isLoaded: boolean) {
    if (isLoaded) this.isConfigLoaded = true;
  }

  mounted(): void {
    console.log('mounted', this.isConfigLoaded);
    this.isMounted = true;
    if (this.isConfigLoaded) {
      this.loadItem();
    }
    console.log('rep', reproject);
  }

  @Watch('isConfigLoaded', { immediate: true })
  onConfigLoadedChange(isConfigLoaded: boolean): void {
    if (this.isMounted && isConfigLoaded) {
      this.loadItem();
    }
  }

  loadItem(): void {
    this.ontologyNames = store.getters.getRegisteredVocabs;

    this.itemId = parseInt(this.$route.params.id as string, 10);

    this.api.getItem(this.itemId).then((itemResponse) => {
      this.item = {
        title: itemResponse[Object.keys(itemResponse).find((x) => x.endsWith('title'))!],
        description: Object.keys(itemResponse).some((x) => x.endsWith('description')) ? itemResponse[Object.keys(itemResponse).find((x) => x.endsWith('description'))!] : '(δεν υπάρχει περιγραφή)',
        thumbnailLarge: Object.keys(itemResponse).some(((x) => x.endsWith('thumbnail_display_urls'))) ? itemResponse[Object.keys(itemResponse).find(((x) => x.endsWith('thumbnail_display_urls')))!].large : '',
        properties: Object.keys(itemResponse).filter((x) => this.ontologyNames.some((y) => x.startsWith(y)) && !x.endsWith(':title') && !x.endsWith(':description')).map((x) => ({
          id: itemResponse[x][0].property_id,
          key: itemResponse[x][0].property_label,
          // value: itemResponse[x][0]['@value'],
          value: itemResponse[x],
        })),
      };

      this.geoJSON = this.item.properties.some((x) => x.key === 'Extent')
        ? this.reprojectGeoJSON(JSON.parse((this.item.properties.find((x) => x.key === 'Extent')!.value[0] as any)['@value']))
        : null;
      console.log('ggggg', this.geoJSON);

      this.api.getResourceTemplateById(itemResponse['o:resource_template']['o:id']).then((templateResponse) => {
        this.labelMappings = templateResponse['o:resource_template_property'].map((x: any) => ({ propertyId: x['o:property']['o:id'], label: x['o:alternate_label'] }));
        console.log('mappings', this.labelMappings);
      });

      if ('o-module-folksonomy:tag' in itemResponse) {
        this.tags = itemResponse['o-module-folksonomy:tag'].map((x: any) => x['o:id']);
      }

      if ('o-module-mapping:marker' in itemResponse) {
        this.hasLocation = true;
        this.api.getMarkerLocation(itemResponse['o-module-mapping:marker'][0]['o:id']).then((locationResponse) => {
          this.location = {
            lat: locationResponse['o-module-mapping:lat'],
            lon: locationResponse['o-module-mapping:lng'],
          };
          this.$nextTick(() => {
            this.initMap();
          });
        });
      }

      if ('o:media' in itemResponse && itemResponse['o:media'].length) {
        this.media = [];
        const promises = itemResponse['o:media'].map((x: any) => this.api.getMedia(x['o:id']));
        Promise.all(promises).then((x) => {
          this.media = x.map((y: any) => ({
            id: y['o:id'],
            itemId: y['o:item']['o:id'],
            title: y['o:title'] || '',
            fileName: y['o:filename'] || '',
            url: y['o:original_url'] || '',
            resourceTemplateId: y['o:resource_template'] ? y['o:resource_template']['o:id'] : null,
            imageSquare: y.thumbnail_display_urls.square.split('?')[0],
            imageMedium: y.thumbnail_display_urls.medium.split('?')[0],
            imageLarge: y.thumbnail_display_urls.large.split('?')[0],
            properties: Object.keys(y).filter((z) => this.ontologyNames.some((o) => z.startsWith(o))).map((z) => ({
              id: y[z][0].property_id,
              key: y[z][0].property_label,
              // value: y[z][0]['@value'],
              value: y[z],
            })),
          }));
          console.log('m', this.media);
        });
      }
    });
  }

  // eslint-disable-next-line
  reprojectGeoJSON(geoJSON: GeoJsonObject & { crs?: { type: string, properties: { name: string } } }): GeoJsonObject {
    if (geoJSON.crs?.properties?.name && geoJSON.crs?.properties?.name.includes('2100')) {
      console.log('need reprojection');
      const crss = {
        'EPSG:2100': '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs',
      };
      const geoJSONreprojected = reproject(geoJSON, 'EPSG:2100', proj4.WGS84, crss);
      console.log('RRRRR', geoJSONreprojected);
      return geoJSONreprojected;
    }
    return geoJSON;
  }

  initMap(): void {
    console.log('init map', this.location?.lat || 'u', this.location?.lon || 'u');
    const map = L.map('map', { maxZoom: 22 }).setView([this.location?.lat || 0, this.location?.lon || 0], 13);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxNativeZoom: 19,
      maxZoom: 22,
    }).addTo(map);

    map.attributionControl.setPrefix('');

    if (this.geoJSON) {
      console.log('yeap, geojson exists', this.geoJSON);
      const geoJSON = L.geoJSON(this.geoJSON).addTo(map);
      console.log(this.geoJSON);

      map.fitBounds(geoJSON.getBounds());
    } else {
      L.circleMarker([this.location?.lat || 0, this.location?.lon || 0]).addTo(map);
    }
  }

  onOpenMedia(id: number) {
    this.$router.push({
      name: 'MediaItem',
      params: {
        id,
        media: JSON.stringify(this.media?.find((x) => x.id === id)) || null,
      },
    });
  }

  onSelectTag(tag: string): void {
    this.$router.push(`/tags/${tag}`);
  }

  // eslint-disable-next-line
  goToMediaSection(): void {
    const y = (document.querySelector('.section-media') as HTMLElement).offsetTop;
    window.scrollTo({ top: y - 60, behavior: 'smooth' });
  }

  downloadGeoJSON(): void {
    fileDownload(JSON.stringify(this.geoJSON!), `geometry_item${this.$route.params.id}.geojson`);
  }
}
